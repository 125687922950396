/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'


const HeaderUserMenu: FC = () => {

  const { currentUser, logout } = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-2'>
          <div className='profile-menu-icon me-3'>
            <i className="bi bi-person-circle"></i>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-4 text-gray-700 text-capitalize'>
              {currentUser?.first_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 pt-2 ms-3'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div> */}

      <Languages />

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-2'>
        <a onClick={logout} className='menu-link px-5'>
          <FormattedMessage id="Sign out" />
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
