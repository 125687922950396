import React from 'react'

const Loading = () => {
    return (
        <div className='text-center fs-3 text-gray-700 my-20'>
            Loading...
        </div>
    )
}

export default Loading
