import React from 'react'
import { Button as BRButton, ButtonProps } from 'react-bootstrap'

import Spinner from './Spinner'

const Button: React.FC<React.PropsWithChildren<{
    loading?: boolean
}> & ButtonProps> = ({ children, loading, disabled, ...props }) => {

    return (
        <BRButton disabled={disabled ?? loading} {...props}>
            {loading && <Spinner size='sm' />}
            {children}
        </BRButton>
    )
}

export default Button
