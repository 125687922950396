/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import clsx from 'clsx'
// import { Link } from 'react-router-dom'

import {
  KTSVG,
  // toAbsoluteUrl 
} from '../../../helpers'
import { useLayout } from '../../core'
import { Topbar } from './Topbar'

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { aside } = config

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch h-75px', process.env.REACT_APP_IS_DEV === 'true' ? 'header-dev' : '')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-40px h-40px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
            </div>
          </div>
        )}

        {/* {!aside.display && (
          <div className='d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' />
            </Link>
          </div>
        )} */}

        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          <div className='d-flex align-items-stretch flex-shrink-0 ms-auto'>
            <Topbar />
          </div>
        </div>
      </div>
    </div>
  )
}
