import {FC} from 'react'

const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bold text-danger mb-10' style={{fontSize:"140px"}}>500</h1>
      <h1 className='fw-bolder fs-2x text-gray-700 mb-10'>Whoops... System Error !</h1>
      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Something went wrong! <br /> Please try again later.
      </div>
    </>
  )
}

export {Error500}
