const QUERIES = {
  USERS_LIST: 'users-list',
  DEPOSIT_TRANSACTION_LIST: 'deposit-transaction-list',
  WITHDRAWAL_TRANSACTION_LIST: 'withdrawal-transaction-list',
  BUY_TRANSACTION_LIST: 'buy-transaction-list',
  SELL_TRANSACTION_LIST: 'sell-transaction-list',
  POKER_PARTNER_LIST:'poker-partner-list',
  DISPUTE_LIST: 'dispute-list',
  FAQ_LIST: 'faq-list',
  NOTICE_LIST: 'notice-list'

}

export {QUERIES}
