/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { useTable, ColumnInstance, Row, Column, HeaderProps, Cell, CellProps } from 'react-table';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { UsersListLoading } from '../../apps/user-management/users-list/components/loading/UsersListLoading';
import { KTCardBody } from '../../../../_metronic/helpers';
import useCanAccess, { Permission } from '../../../hooks/useCanAccess';
import Button from '../../../utility/Button';

import ConfirmationModal from '../../../components/ConfirmationModal';
import { CustomerHeaderColumn, CustomRow } from '../../../components/table';

import { useQueryResponseData, useQueryResponseLoading, useRefetchRequest } from '../context/QueryResponseProvider';

import { Notice } from '../_models';
import { archiveNotice } from '../requests';

import Pagination from './Pagination';
import CustomHeader from './CustomHeader';


interface ColumnCellProps {
  value?: string
}

const ColumnCell = ({ value }: ColumnCellProps) => (
  <div className='d-flex align-items-center'>
    {value && <div className='d-flex flex-column text-gray-800 text-hover-primary mb-1 text-truncate' style={{ 'maxWidth': '300px' }} dangerouslySetInnerHTML={{ __html: value }} />}
  </div>
)
const NoticeTable = () => {

  const transactions = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const refetch = useRefetchRequest()
  const { formatMessage } = useIntl()
  const data = useMemo(() => transactions, [transactions])
  const navigate = useNavigate()
  const canAccess = useCanAccess()

  const [deleteId, setDeleteId] = useState('');
  const [isOpen, setOpen] = useState(false);

  const archiveFaqMutation = useMutation((uuid: string) => archiveNotice(uuid))

  const isChangingStatus = useMemo(() => !!deleteId, [deleteId])

  const showConfirmation = (uuid: string) => {
    setOpen(true);
    setDeleteId(uuid)
  };

  const columns = useMemo<Array<Column<Notice>>>(() => [

    {
      Header: (props: React.PropsWithChildren<HeaderProps<Notice>>) => (
        <CustomHeader tableProps={props} title='Title in english' className='min-w-125px' />
      ),
      id: 'title_en',
      Cell: ({ row: { original } }: React.PropsWithChildren<CellProps<Notice>>) => <ColumnCell value={original.title_en} />,
    },
    {
      Header: (props: React.PropsWithChildren<HeaderProps<Notice>>) => (
        <CustomHeader tableProps={props} title='Title in japanese' className='min-w-125px' />
      ),
      id: 'title_ja',
      Cell: ({ row: { original } }: React.PropsWithChildren<CellProps<Notice>>) => <ColumnCell value={original.title_ja} />,
    },

    {
      Header: (props: React.PropsWithChildren<HeaderProps<Notice>>) => (
        <CustomHeader tableProps={props} title='Actions' />
      ),
      id: 'actions',
      Cell: ({ row: { original } }: React.PropsWithChildren<Cell<Notice>>) => (
        <div className='d-flex'>
          {canAccess(Permission.READ_FAQ) && <Button className='btn btn-primary btn-sm' title='Detail' style={{ 'marginRight': '10px' }} disabled={isChangingStatus} onClick={() => navigate(`/notice/${original.slug}/detail`)}>
            <i className="fas fa-eye"></i>
          </Button>}
          <div className=''>
            {canAccess(Permission.WRITE_FAQ) && <Button className='btn btn-danger btn-sm' data-toggle='tooltip' title='Edit' style={{ 'marginRight': '10px' }} disabled={isChangingStatus} onClick={() => showConfirmation(original.slug)}>
              <i className="far fa-trash-alt"></i>
            </Button>}
            {canAccess(Permission.WRITE_FAQ) && <Button className='btn btn-success btn-sm' data-bs-toggle="tooltip" title='verify' style={{ 'marginRight': '10px' }} onClick={() => navigate(`/notice/${original.slug}/edit`)} disabled={isChangingStatus}>
              <i className="far fa-edit"></i>
            </Button>}
          </div>
        </div>
      ),
    },
  ], [isChangingStatus, deleteId])

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  })

  const onConfirm = () => {
    archiveFaqMutation.mutate(deleteId, {
      onSuccess() {
        toast.success(formatMessage({
          id: 'Faq deleted successfully'
        }))
        setDeleteId('')
        refetch()
        setOpen(false)
      }
    })
  }

  const closeModal = () => {
    setOpen(false)
    setDeleteId('')
  }

  return (
    <div>
      <ConfirmationModal isOpen={isOpen} onClose={closeModal} onConfirm={onConfirm}>
        <FormattedMessage id='Are you sure you want archive this record?' />
      </ConfirmationModal>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<Notice>) => (
                  <CustomerHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Notice>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </div>
  )

}

export default NoticeTable
