import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  size: 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  limit?: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}
export interface TypeState {
  type?: number | string
}

export interface StatusState {
  status_id?: string | string[];
}

export interface StatusTypeState {
  transaction_status?: string | string[];
}

export interface PartnerState {
  partner_id?: string
}

export interface isActiveStatus {
  is_active?: string
}

export interface isActiveStatus {
  includeZero?: string
}

export interface isUnreadMessage {
  is_unread_message?: string
}

export interface DateFilterState {
  from?: string
  to?: string
}

export interface SelectState {
  select?: string
}

export type QueryState = PaginationState &
  SortState &
  SelectState &
  FilterState &
  SearchState &
  TypeState &
  StatusState &
  DateFilterState &
  isActiveStatus &
  isUnreadMessage &
  StatusTypeState &
  PartnerState
export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  size: 10,
  status_id: '',
  is_active: 'true',
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  statusId: string
  setStatusId: Dispatch<SetStateAction<string>>
  filterTerm: string
  setFilterTerm: Dispatch<SetStateAction<string>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  filterTerm: '',
  statusId: '',
  setFilterTerm: () => {},
  setStatusId: () => {},
}
