import React from 'react'

const ForbiddenPage = () => {

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Forbidden</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked forbidden!
      </div>
    </>

  )
}

export default ForbiddenPage
