import React from 'react'
import { Outlet } from 'react-router-dom'

import useCanAccess, { Permission } from '../hooks/useCanAccess'
import ForbiddenPage from '../pages/ForbiddenPage'

interface Props {
    permissions: Permission[]
}
const AuthorizationHoc: React.FC<Props> = ({ permissions }) => {

    const canAccess = useCanAccess()

    if (canAccess(...permissions)) {
        return <Outlet />
    }

    return <ForbiddenPage />
}


export default AuthorizationHoc
