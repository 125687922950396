import axios from "axios";

import { NoticePayload } from "./schema";
import { Notice, NoticeQueryResponse } from "./_models";

const prefix = '/v1/admin/notice'

export const storeNotice = (payload: NoticePayload) => axios.post(prefix, payload)

export const fetchNotices = async (query: string): Promise<NoticeQueryResponse> => {
    const response = await axios.get(`${prefix}?${query}`)
    return response.data
}

export const archiveNotice = (slug: string) => axios.delete(`${prefix}/${slug}`)

export const fetchNoticeDetail = async (slug: string) : Promise<Notice> => {
    const { data } = await axios.get(`${prefix}/${slug}`)
    return data
}

export const updateNotice = (slug: string, payload: NoticePayload) => axios.patch(`${prefix}/${slug}`,payload)
