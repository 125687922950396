import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDate = (date: string | Date | undefined, format: string = 'YYYY/MM/DD') => {
  return dayjs(date).format(format)
}

export const formatDateTime = (
  dateTime: string | Date | undefined,
  format: string = 'YYYY/MM/DD HH:mm:ss'
) => {
  return dayjs(dateTime).format(format)
}

export const formatPWCDateTime = (
  dateTime: string | Date | undefined,
  format: string = 'DD/MM/YYYY - HH:mm'
) => {
  return dayjs(dateTime).tz('America/Sao_Paulo').format(format)
}
