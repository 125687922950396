import {useAuth} from '../modules/auth'
import intersection from 'lodash/intersection'

export enum Permission {
  LIST_TRANSACTION = 'list-transactions',
  READ_TRANSACTION = 'read-transactions',
  VERIFY_TRANSACTION = 'verify-transactions',
  LIST_POKERS = 'list-poker-transactions',
  READ_POKERS = 'read-poker-transactions',
  VERIFY_POKERS = 'verify-poker-transactions',
  LIST_USERS = 'list-users',
  UPDATE_USERS = 'update-users',
  LIST_NOTIFICATIONS = 'list-notifications',
  LIST_FAQS = 'list-faqs',
  CREATE_FAQS = 'create-faqs',
  READ_FAQS = 'read-faqs',
  UPDATE_FAQS = 'update-faqs',
  DELETE_FAQS = 'delete-faqs',
  LIST_NOTICES = 'list-notices',
  CREATE_NOTICES = 'create-notices',
  UPDATE_NOTICES = 'update-notices',
  DELETE_NOTICES = 'delete-notices',
  CREATE_ADMIN = 'create-admins',
  UPDATE_ADMIN = 'update-admins',
  LIST_ADMIN = 'list-admins',
  EDIT_ADMIN = 'edit-admins',

  READ_DEPOSIT_REQUEST = 'read-deposit-request',
  WRITE_DEPOSIT_REQUEST = 'write-deposit-request',

  READ_WITHDRAWAL_TRANSACTION = 'read-withdrawal-transactions',
  WRITE_WITHDRAWAL_TRANSACTION = 'write-withdrawal-transactions',

  READ_BUY_TRANSACTION = 'read-buy-transactions',
  WRITE_BUY_TRANSACTION = 'write-buy-transactions',

  READ_SELL_TRANSACTION = 'read-sell-transactions',
  WRITE_SELL_TRANSACTION = 'write-sell-transactions',

  DISPUTES = 'read-disputes',

  READ_GAME_ACCOUNTS = 'read-game-accounts',
  WRITE_GAME_ACCOUNTS = 'write-game-accounts',

  READ_TRADE_BOARD = 'read-trade-board',
  WRITE_TRADE_BOARD = 'write-trade-board',

  READ_USERS = 'read-users',
  WRITE_USERS = 'write-users',

  READ_COMMISSION = 'read-commission',
  WRITE_COMMISSION = 'write-commission',

  READ_BANK_SETTING = 'read-bank-setting',
  WRITE_BANK_SETTING = 'write-bank-setting',

  READ_SETTINGS = 'read-settings',
  WRITE_SETTINGS = 'write-settings',

  READ_KPI = 'read-kpi',
  WRITE_KPI = 'write-kpi',

  READ_FAQ = 'read-faq',
  WRITE_FAQ = 'write-faq',

  READ_ADMINS = 'read-admins',
  WRITE_ADMINS = 'write-admins',

  READ_ROLES = 'read-roles',
  WRITE_ROLES = 'write-roles',

  NOTIFICATIONS = 'read-notifications',

  READ_EMAILS = 'read-emails',
  WRITE_EMAILS = 'write-emails',

  READ_CAMPAIGN = 'read-campaign',
  WRITE_CAMPAIGN = 'read-campaign',
}

type Callback = () => (...p: Permission[]) => boolean

const useCanAccess: Callback = () => {
  const context = useAuth()
  const user = context.currentUser
  const userPermissions = user?.adminRoles
    ? user?.adminRoles?.map((ar) => ar.role.permissions.map((p) => p.permission.name)).flat(2)
    : []

  return (...permissions: Permission[]): boolean => {
    const accepted = intersection(userPermissions, permissions)
    return accepted.length > 0
  }
}

export default useCanAccess
