import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import Button from '../utility/Button'

interface IProps {
    onConfirm: () => void
    onClose: () => void
    isOpen: boolean
    isLoading?: boolean
    isConfirmDisabled?: boolean
}
const ConfirmationModal: React.FC<React.PropsWithChildren<IProps>> = ({
    isOpen,
    onClose,
    children,
    onConfirm,
    isConfirmDisabled,
    isLoading = false,
}) => {
    const [isSaving, setSaving] = useState(false)

    const handleConfirm = async () => {
        setSaving(true)
        return Promise.resolve(onConfirm()).then(() => setSaving(false))
    }

    const handleOnHide = () => {
        if (isLoading) {
            return
        }

        return onClose()
    }

    return (
        <Modal show={isOpen} onHide={handleOnHide}>
            <Modal.Body className='fs-4 text-gray-700 px-8 pt-8 pb-4'>{children}</Modal.Body>
            <Modal.Footer className='gap-6 px-8 pt-6 pb-6'>
                <Button
                    id="confirmationModal-action-handleClose"
                    variant='secondary'
                    className='m-0'
                    onClick={onClose}
                    disabled={isSaving || isLoading}
                >
                    <FormattedMessage id={'No'} />
                </Button>

                <Button
                    id="confirmationModal-action-handleConfirm"
                    variant='primary'
                    className='m-0'
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled || isSaving || isLoading}
                >
                    <FormattedMessage id={(isSaving || isLoading) ? 'Please wait' : 'Confirm'} />
                    &nbsp;
                    {(isSaving || isLoading) && <Spinner animation='grow' size='sm' />}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal
