import React from 'react'
import {Card, ListGroup} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Route, Routes, Outlet} from 'react-router-dom'
import Paginate from 'react-paginate'

import {formatDate} from '../../_metronic/helpers'
import AuthorizationHoc from '../authorization/AuthroizationHoc'
import {Permission} from '../hooks/useCanAccess'
import useNotifications, {
  NotificationType,
  TransactionType,
  NotificationProps,
} from '../hooks/useNotifications'
import Loading from '../utility/Loading'

export const NotificationMessage = ({
  notification,
  showDate = true,
}: {
  notification: NotificationProps
  showDate: boolean
}) => {
  const {formatMessage} = useIntl()

  if (notification.type === NotificationType.REGISTRATION) {
    return (
      <div className='d-flex justify-content-between gap-10'>
        <FormattedMessage
          id='New customer {customer} has been registered. Please update status of the customer'
          values={{
            customer: notification?.customer_katakana_name,
          }}
        />
        {showDate && (
          <span className='text-gray-700'>
            {formatDate(notification.created_at, 'MMM D, YYYY hh:mm A')}
          </span>
        )}
      </div>
    )
  }

  if (notification) {
    if (notification.transaction_type === TransactionType.DEPOSIT || notification.transaction_type === TransactionType.WITHDRAW) {
      return (
        <div className='d-flex justify-content-between gap-10'>
          <FormattedMessage
            id='{transactionType} transaction has been made by {customer}. Update transaction status accordingly'
            values={{
              customer: notification.customer_katakana_name,
              transactionType: formatMessage({
                id: notification.transaction_type,
              }),
            }}
          />
          {showDate && (
            <span className='text-gray-700'>
              {formatDate(notification.created_at, 'MMM D, YYYY hh:mm A')}
            </span>
          )}
        </div>
      )
    }
  
    if (notification.transaction_type === TransactionType.BUY || notification.transaction_type === TransactionType.SELL) {
      return (
        <div className='d-flex justify-content-between gap-10'>
          <FormattedMessage
            id='{customer} has added transaction to {transactionType} token from {pokerPartnerName}.Update transaction status accordingly'
            values={{
              customer: notification?.customer_katakana_name,
              transactionType: formatMessage({
                id: notification.transaction_type,
              }),
              pokerPartnerName: notification.transaction_poker_partner_name,
            }}
          />
  
          {showDate && (
            <span className='text-gray-700'>
              {formatDate(notification.created_at, 'MMM D, YYYY hh:mm A')}
            </span>
          )}
        </div>
      )
    }
  }

  return null
}

const Notifications = () => {
  const {notifications, isLoading, onPageChange, page, redirectToDetailPage} = useNotifications()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <h1 className='fs-2x mb-10'>Notifications</h1>
      <Card>
        <Card.Body className='px-9 py-7'>
          <ListGroup className='rounded-0'>
            {notifications?.data?.length ? (
              notifications?.data.map((notification: NotificationProps) => (
                <ListGroup.Item
                  action
                  variant='light'
                  key={notification.id}
                  style={{cursor: 'pointer'}}
                  className='py-6 border-0'
                  onClick={() => redirectToDetailPage(notification)}
                >
                  <NotificationMessage notification={notification} showDate />
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item className='py-3 border-0 text-center fs-4 text-gray-600'>
                <FormattedMessage id='No records found' />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>

        <Card.Footer className='px-7 py-6'>
          <div className='d-flex justify-content-end'>
            <Paginate
              forcePage={page - 1}
              containerClassName='pagination'
              pageCount={Math.ceil(
                notifications!.payload!.pagination.total / notifications!.payload.pagination.size
              )}
              activeClassName='active'
              onPageChange={onPageChange}
              pageClassName='page-item'
              previousClassName='page-item '
              previousLinkClassName='page-link previous'
              nextLinkClassName='page-link next'
              pageLinkClassName='page-link'
              nextClassName='page-item next'
              previousLabel='« Previous'
              nextLabel='Next »'
            />
          </div>
        </Card.Footer>
      </Card>
    </>
  )
}

const NotificationRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route element={<AuthorizationHoc permissions={[Permission.NOTIFICATIONS]} />}>
        <Route path='' element={<Notifications />} />
        <Route index element={<Notifications />} />
      </Route>
    </Routes>
  )
}

export default NotificationRoutes
