import * as yup from 'yup'
import { FormikHelpers } from 'formik'

const NoticeSchema = yup.object({
    title_en: yup.string().required('Required'),
    title_ja: yup.string().required('Required'),
    description_en: yup.string().required('Required'),
    description_ja: yup.string().required('Required'),
})

export type NoticePayload = yup.InferType<typeof NoticeSchema>

export type NoticeKeys = keyof NoticePayload

export type SubmitProps = (values:NoticePayload, props: FormikHelpers<NoticePayload>) => void
 
export default NoticeSchema
