import React from 'react'
import { Form, Formik } from 'formik'
import { Card, Container, Form as BForm } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import Button from '../../utility/Button'
import TextEditor from '../../utility/TextEditor'

import NoticeSchema, { NoticePayload, SubmitProps } from './schema'

interface Props {
    onSubmit: SubmitProps
    text: JSX.Element,
    notice?: NoticePayload,
    title: JSX.Element
}


const NoticeForm = ({ onSubmit, text, notice, title }: Props) => {

    const initialValues: NoticePayload = {
        title_en: notice?.title_en ?? '',
        title_ja: notice?.title_ja ?? '',
        description_en: notice?.description_en ?? '',
        description_ja: notice?.description_ja ?? ''
    }

    return (
        <Container>
            <Card>
                <Card.Header className='justify-content-center align-items-center' >
                    <h1 className='mt-5 text-center '>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={NoticeSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            ({ isSubmitting, values, handleSubmit, handleChange, handleBlur }) => (
                                <Form onSubmit={handleSubmit}>
                                    <BForm.Group className='mb-5'>
                                        <BForm.Label>
                                            <FormattedMessage id='Title in english' />
                                        </BForm.Label>
                                        <BForm.Control name="title_en" onChange={handleChange} onBlur={handleBlur} value={values.title_en} />
                                    </BForm.Group>

                                    <BForm.Group className='mb-5'>
                                        <BForm.Label><FormattedMessage id='Description in english' /></BForm.Label>
                                        <TextEditor name="description_en" value={values?.description_en} />
                                    </BForm.Group>

                                    <hr />
                                    <BForm.Group className='mb-5'>
                                        <BForm.Label><FormattedMessage id='Title in japanese' /></BForm.Label>
                                        <BForm.Control name="title_ja" onChange={handleChange} onBlur={handleBlur} value={values.title_ja} />
                                    </BForm.Group>

                                    <BForm.Group className='mb-5'>
                                        <BForm.Label><FormattedMessage id='Description in japanese' /></BForm.Label>
                                        <TextEditor name="description_ja" value={values?.description_ja} />
                                    </BForm.Group>

                                    <Button loading={isSubmitting} type="submit">
                                        {text}
                                    </Button>

                                </Form>
                            )
                        }
                    </Formik>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default NoticeForm
