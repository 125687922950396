/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import clsx from 'clsx'

import { toAbsoluteUrl } from '../../../_metronic/helpers'

import { Registration } from './components/Registration'
// import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className={
        clsx(
          'd-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed',
          process.env.REACT_APP_IS_DEV === 'true' ? 'background-dev' : ''
        )
      }
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/illustrations/easy/1.svg')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>

        <div className='mb-10 text-dark fs-2x fw-bold'>PointStore Admin</div>

        <div className='w-lg-475px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
