import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css';
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'

import { AuthInit } from './modules/auth'

import { ToastContainer } from 'react-toastify'
const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <ToastContainer />
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
