import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import NoticeForm from "./NoticeForm";
import { fetchNoticeDetail, updateNotice } from "./requests";
import { NoticePayload, SubmitProps } from "./schema";

const EditNotice: React.FC = () => {
    const { notice } = useParams()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { isLoading, data } = useQuery(['notice', notice], () => fetchNoticeDetail(notice as string), {
        cacheTime: 0
    })

    const mutation = useMutation((values: NoticePayload) => updateNotice(notice as string, values))

    const onSubmit: SubmitProps = (values: NoticePayload, { setSubmitting }) => {

        mutation.mutate(values, {
            onSuccess() {
                toast.success(formatMessage({ id: 'Faq updated successfully' }))
                navigate('/notice')
            },
            onError() {
                toast.error(formatMessage({
                    id: 'Something went wrong. Please try again'
                }))
            },
            onSettled() {
                setSubmitting(false)
            }
        })
    }

    if (isLoading) {
        return <Spinner animation="border" />
    }

    return (
        <NoticeForm
            onSubmit={onSubmit}
            text={<FormattedMessage id="Update" />}
            title={<FormattedMessage id="Edit notice" />}
            notice={data}
        />
    )
}

export default EditNotice
