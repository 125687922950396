// import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setErrors }) => {
      try {
        const response = await login(values.email, values.password)

        if (response.status === 200) {
          setErrors({})
          const { data: auth } = response
          saveAuth(auth)

          const { data: user } = await getUserByToken(auth.api_token)

          setCurrentUser(user)

          return user
        }

        return response
      } catch (error) {
        console.error(error)
        setErrors({ password: error.response.data.message })
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
      }
    },
  })


  return (
    <form
      className='form w-100 d-flex flex-column gap-10'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='fv-row'>
        <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          id="email"
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger pt-1 px-2'>
            <span role='alert'><FormattedMessage id={formik.errors.email} /></span>
          </div>
        )}
      </div>

      <div className='fv-row mb-2'>
        <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='password'>Password</label>

        <input
          id='password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid mb-1',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-danger px-2'>
            <span role='alert'><FormattedMessage id={formik.errors.password} /></span>
          </div>
        )}

        {/* <div className='mt-4'>
          <Link
            to='/auth/forgot-password'
            className='link-primary text-end p-2 fs-6 fw-bolder'
          >
            Forgot Password ?
          </Link>
        </div> */}

      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {formik.isSubmitting ? <span className='indicator-progress' style={{ display: 'block' }}>
            <FormattedMessage id='Please wait' />...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span> : <span className='indicator-label'><FormattedMessage id='Login' /></span>}
        </button>
      </div>
    </form>
  )
}
