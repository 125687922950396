import React, { FC } from 'react'
import clsx from 'clsx'
// import { Dropdown } from 'react-bootstrap'

// import { KTSVG } from '../../../helpers'
import { 
  // HeaderNotificationsMenu, 
  HeaderUserMenu } from '../../../partials'
// import useCanAccess, { Permission } from '../../../../app/hooks/useCanAccess'

const toolbarButtonMarginClass = '',
  // toolbarButtonHeightClass = 'w-60px h-50px px-2',
  toolbarUserAvatarHeightClass = 'symbol-50px'

const Topbar: FC = () => {
  // const canAccess = useCanAccess()

  return (
    <div className='d-flex align-items-stretch gap-6 flex-shrink-0'>
      {/* {
        canAccess(Permission.NOTIFICATIONS) ? (
          <Dropdown className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <Dropdown.Toggle
              className={clsx(
                'btn btn-md btn-icon btn-color-primary btn-active-light-primary',
                toolbarButtonHeightClass
              )}
              variant={'none'}
              title="Notification"
            >
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='svg-icon-2x' />
            </Dropdown.Toggle>
            
            <Dropdown.Menu className='w-300px w-sm-400px mx-sm-0 py-0 rounded-4 notification-dropdown'>
              <HeaderNotificationsMenu />
            </Dropdown.Menu>
          </Dropdown>
        ) : null
      } */}

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <button
          id='topbar-action-profile-toggle'
          className={clsx('profile-menu-icon-button', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <i className="bi bi-person-circle"></i>
        </button>

        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Topbar }
