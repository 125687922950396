/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout()
  const { aside } = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'dark' && (
          <Link to='/'>
            <h1 className='logo m-0 text-white fw-bolder fs-1'>
             PointStore
            </h1>
          </Link>
        )}

        {aside.theme === 'light' && (
          <Link to='/'>
            <img
              alt='Logo'
              className='h-25px logo'
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
            />
          </Link>
        )}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-2x rotate-180'}
            />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <div className='d-flex d-lg-none justify-content-center px-4 pt-8 pb-4'>
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' />
          </Link>
        </div>

        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
    </div>
  )
}

export { AsideDefault }
