import { FC } from 'react'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import { useLang } from './Metronici18n'
import enMessages from './messages/en.json'
import jaMessages from './messages/ja.json'

const allMessages = {
  en: enMessages,
  ja: jaMessages,
}

const I18nProvider: FC = ({ children }) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages} >
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
