import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery} from 'react-query'

import useCanAccess, {Permission} from '../../../../app/hooks/useCanAccess'
import {getTransactionCount, TransactionType} from '../../../services/count.service'
import {AsideMenuItem} from './AsideMenuItem'
import {KTSVG} from '../../../helpers'

export function AsideMenuMain() {
  const intl = useIntl()

  const {data: depositCount} = useQuery('deposit-transaction-count', () =>
    getTransactionCount(TransactionType.DEPOSIT)
  )
  const {data: withdrawalCount} = useQuery('withdraw-transaction-count', () =>
    getTransactionCount(TransactionType.WITHDRAW)
  )
  const {data: buyCount} = useQuery('buy-transaction-count', () =>
    getTransactionCount(TransactionType.BUY)
  )
  const {data: sellCount} = useQuery('sell-transaction-count', () =>
    getTransactionCount(TransactionType.SELL)
  )

  const canAccess = useCanAccess()
  const navs = [
    // {
    //   to: '/dashboard',
    //   icon: '/media/icons/duotune/files/fil002.svg',
    //   title: intl.formatMessage({ id: 'Dashboard' }),
    //   fontIcon: 'bi-app-indicator',
    // },
    {
      to: '/transactions/deposit',
      icon: '/media/icons/duotune/finance/fin001.svg',
      title: intl.formatMessage({
        id: 'Deposit Request',
      }),
      badge: depositCount?.count ? (
        <span className='badge badge-circle badge-secondary'>{depositCount.count ?? 0}</span>
      ) : undefined,
      secondaryBadge: depositCount?.totalTransactionMessageUnreadCount ? (
        <div className='position-relative mt-1'>
          <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-2' />
          <span className='position-absolute top-0 start-100 translate-middle badge rounded-circle px-2 py-1 bg-danger'>
            {depositCount.totalTransactionMessageUnreadCount ?? 0}
            <span className='visually-hidden'>unread messages</span>
          </span>
        </div>
      ) : undefined,
      canAccess: !canAccess(Permission.READ_DEPOSIT_REQUEST),
    },
    {
      to: '/transactions/withdrawal',
      icon: '/media/icons/duotune/finance/fin003.svg',
      title: intl.formatMessage({
        id: 'Withdrawal Transactions',
      }),
      badge: withdrawalCount?.count ? (
        <span className='badge badge-circle badge-secondary'>{withdrawalCount.count ?? 0}</span>
      ) : undefined,
      secondaryBadge: withdrawalCount?.totalTransactionMessageUnreadCount ? (
        <div className='position-relative mt-1'>
          <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-2' />
          <span className='position-absolute top-0 start-100 translate-middle badge rounded-circle px-2 py-1 bg-danger'>
            {withdrawalCount.totalTransactionMessageUnreadCount ?? 0}
            <span className='visually-hidden'>unread messages</span>
          </span>
        </div>
      ) : undefined,
      canAccess: !canAccess(Permission.READ_WITHDRAWAL_TRANSACTION),
    },
    {
      to: '/transactions/buy',
      icon: '/media/icons/duotune/finance/fin005.svg',
      title: intl.formatMessage({
        id: 'Buy Transactions',
      }),
      badge: buyCount?.count ? (
        <span className='badge badge-circle badge-secondary'>{buyCount.count ?? 0}</span>
      ) : undefined,
      secondaryBadge: buyCount?.totalTransactionMessageUnreadCount ? (
        <div className='position-relative mt-1'>
          <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-2' />
          <span className='position-absolute top-0 start-100 translate-middle badge rounded-circle px-2 py-1 bg-danger'>
            {buyCount.totalTransactionMessageUnreadCount ?? 0}
            <span className='visually-hidden'>unread messages</span>
          </span>
        </div>
      ) : undefined,
      canAccess: !canAccess(Permission.READ_BUY_TRANSACTION),
    },
    {
      to: '/transactions/sell',
      icon: '/media/icons/duotune/finance/fin004.svg',
      title: intl.formatMessage({
        id: 'Sell Transactions',
      }),
      badge: sellCount?.count ? (
        <span className='badge badge-circle badge-secondary'>{sellCount.count ?? 0}</span>
      ) : undefined,
      secondaryBadge: sellCount?.totalTransactionMessageUnreadCount ? (
        <div className='position-relative mt-1'>
          <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-2' />
          <span className='position-absolute top-0 start-100 translate-middle badge rounded-circle px-2 py-1 bg-danger'>
            {sellCount.totalTransactionMessageUnreadCount ?? 0}
            <span className='visually-hidden'>unread messages</span>
          </span>
        </div>
      ) : undefined,
      canAccess: !canAccess(Permission.READ_SELL_TRANSACTION),
    },
    {
      to: '/game-account',
      icon: '/media/icons/duotune/communication/com014.svg',
      title: intl.formatMessage({
        id: 'Game Name',
      }),
      canAccess: !canAccess(Permission.READ_GAME_ACCOUNTS),
    },
    {
      to: '/token',
      icon: '/media/icons/duotune/general/gen028.svg',
      title: intl.formatMessage({
        id: 'Trade Board',
      }),
      canAccess: !canAccess(Permission.READ_TRADE_BOARD),
    },
    {
      to: '/email',
      icon: '/media/icons/duotune/art/art002.svg',
      title: intl.formatMessage({id: 'Send Email'}),
      canAccess: !canAccess(Permission.READ_EMAILS),
    },
    {
      to: '/user-management/users',
      icon: '/media/icons/duotune/communication/com013.svg',
      title: intl.formatMessage({id: 'Users'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_USERS),
    },
    {
      to: '/commission',
      icon: '/media/icons/duotune/finance/fin007.svg',
      title: intl.formatMessage({id: 'Commission'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_COMMISSION),
    },
    {
      to: '/settings',
      icon: '/media/icons/duotune/coding/cod001.svg',
      title: intl.formatMessage({id: 'Settings'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_SETTINGS),
    },
    {
      to: '/kpi',
      icon: '/media/icons/duotune/graphs/gra004.svg',
      title: intl.formatMessage({id: 'Kpi'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_KPI),
    },
    {
      to: '/bank-setting',
      icon: '/media/icons/duotune/finance/fin001.svg',
      title: intl.formatMessage({id: 'Bank Setting'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_BANK_SETTING),
    },
    {
      to: '/faq',
      icon: '/media/icons/duotune/communication/com007.svg',
      title: intl.formatMessage({id: 'MENU.FAQ'}),
      fontIcon: 'bi-layers',
      canAccess: !canAccess(Permission.READ_FAQ),
    },
    {
      to: '/admins',
      icon: '/media/icons/duotune/general/gen051.svg',
      title: intl.formatMessage({id: 'Admins'}),
      canAccess: !canAccess(Permission.READ_ADMINS),
    },
    {
      to: '/roles',
      icon: '/media/icons/duotune/art/art002.svg',
      title: <FormattedMessage id='Roles' />,
      canAccess: !canAccess(Permission.READ_ROLES),
    },
    {
      to: '/campaign',
      icon: 'media/icons/duotune/ecommerce/ecm003.svg',
      title: <FormattedMessage id='Campaign' />,
      canAccess: !canAccess(Permission.READ_CAMPAIGN),
    },
  ]

  const menus = navs.filter((nav) => !nav.canAccess)

  return (
    <>
      {menus.map((menu) => (
        <AsideMenuItem key={menu.to} {...menu} />
      ))}
    </>
  )
}
