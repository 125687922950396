import React, { useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmationModal from '../../components/ConfirmationModal';
import { queryClient } from '../../..';
import { archiveNotice, fetchNoticeDetail } from './requests';

const NoticeDetail = () => {
    const { notice } = useParams()
    const { isLoading, data } = useQuery(['notice', notice], () => fetchNoticeDetail(notice as string))
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const [isOpen, setOpen] = useState(false)
    const archiveMutation = useMutation((value: string) => archiveNotice(value))

    const onConfirm = () => {
        archiveMutation.mutate(notice as string, {
            onSuccess() {
                toast.success(formatMessage({
                    id: 'Faq deleted successfully'
                }))
                queryClient.refetchQueries()
                navigate('/faq')
            }
        })
    }

    if (isLoading) {
        return <Spinner animation='border' />
    }

    return (
        <Card>
            <ConfirmationModal isOpen={isOpen} onClose={() => setOpen(false)} onConfirm={onConfirm} >
                <FormattedMessage id='Are you sure you want archive this record?' />
            </ConfirmationModal>
            <Card.Body>
                <h3><FormattedMessage id='In english' /></h3>
                <div>{data?.title_en}</div>
                <div dangerouslySetInnerHTML={{ __html: data!.description_en }} />
            </Card.Body>
            <Card.Body>
                <h3><FormattedMessage id="In japanese" /></h3>
                <div>{data?.title_ja}</div>
                <div dangerouslySetInnerHTML={{ __html: data!.description_ja }} />
            </Card.Body>
            <Card.Footer>
                <div className='d-flex'>
                    <Button className='btn btn-danger btn-sm' data-toggle='tooltip' title='Delete' style={{ 'marginRight': '10px' }} onClick={() => setOpen(true)}>
                        <i className="far fa-trash-alt"></i>
                    </Button>
                    <Button className='btn btn-success btn-sm' data-bs-toggle="tooltip" title='Edit' style={{ 'marginRight': '10px' }} onClick={() => navigate(`/notice/${data?.slug}/edit`)} disabled={isOpen}>
                        <i className="far fa-edit"></i>
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    )
}

export default NoticeDetail
