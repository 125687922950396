import axios from 'axios'

export enum TransactionType {
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    BUY = 'BUY',
    SELL  = 'SELL'
}

interface TransactionCount {
    count: number
    totalTransactionMessageUnreadCount: number
 }
  
  export const getTransactionCount = async (type: TransactionType, combine_transaction?: string): Promise<TransactionCount> => {
    const { data } = await axios.get('/v2/admin/transaction/count', {
      params: {
        type,
        combine_transaction
      },
    })
  
    return data
  }
  
export const getNotificationsCount = async () : Promise<number> => {
    const {data: { count }} = await axios.get('/admin/v1/notification/count')
    return count
}

export const getPendingDisputeCount = async () : Promise<number> => {
    const {data: { count }} = await axios.get('/v1/dispute/messages/count')
    return count
}
