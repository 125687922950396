import axios from 'axios'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { queryClient } from '../..'

export enum NotificationType {
    REGISTRATION = 1,
    TRANSACTION,
    POKER_TRANSACTION,
    DISPUTE
}

export enum TransactionType {
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    BUY = 'BUY',
    SELL = 'SELL'
}

export enum PokerTransactionType {
    BUY = 1,
    SELL
}

export interface Transaction {
    uuid: string
	amount?: number
	bank_account_number?: string
	bank_id?: number
	bank_name: string
	bank_katakana_name?: string
	bank_type?: string
	bank_branch_name: string
	cancel_message?: string
	commission_amount?: number
	commission_charged_amount?: number
	commission_type?: string | number
	commission_value?: string
	created_at?: string
	customer_email?: string
	customer_entered_amount?: number
	customer_first_name?: string
	customer_id?: number
	customer_last_name?: string
	customer_user_id?: number | string
	customer_username?: string
	deposits_today?: number
	id?: number | string
	katakana_name?: string
	katakana_name_sender?: string
	lot?: number
	poker_partner_name?: string
	poker_partner_id?: string | number
	primary_game_account_field?: string | number
	quantity?: number
	reject_message?: string
	status?: string
	token_id?: number | string
	total_amount?: number | string
	transaction_bank_detail_id?: number | string
	transaction_code?: string
	transaction_date?: Date
	type?: TransactionType
	updated_at?: string
	us_rate?: string | number
}

export interface NotificationProps {
    id: number,
    customer_id: number
    customer_uuid: string | number
    customer_katakana_name: string
    transaction_uuid: string
    transaction_status: string
	transaction_type: string
	transaction_code: string
	transaction_poker_partner_name: string
    type: NotificationType
    created_at: string
}

interface NotificationResponseProps {
    data: NotificationProps[]
    payload: {
        pagination: {
            total: number
            offset: number,
            size: number,
            page: number
        }
    }
}

export const fetchNotifications = async (page: number) => {
    const { data } = await axios.get('/v1/admin/notification', {
        params: {
            page,
        }
    })
    return data
}



const useNotifications = () => {
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { data: notifications, isLoading, } = useQuery<NotificationResponseProps>(['notifications', page], () => {
        return fetchNotifications(page)
    })

    useEffect(() => {
        queryClient.prefetchQuery(['notifications', page], () => fetchNotifications(page))
    }, [page])

    const onPageChange = ({ selected }: { selected: number }) => {
        const newPage = selected + 1
        setPage(newPage)
    };

    const redirectToDetailPage = (notification: NotificationProps) => {
        if (notification.type === NotificationType.REGISTRATION) {
            navigate('/user-management/users/detail/' + notification.customer_uuid)
            return
        }

        if (notification) {

            if (notification.transaction_type === TransactionType.DEPOSIT || notification.transaction_type === TransactionType.WITHDRAW) {
                navigate('/transactions/' + notification.transaction_uuid + '/detail')
                return
            }

            if (notification.transaction_type === TransactionType.BUY || notification.transaction_type === TransactionType.SELL) {
                navigate('/pokers/' + notification.transaction_uuid + '/detail')
                return
            }
        }

        return null;
    }

    return {
        notifications, isLoading, onPageChange, page, redirectToDetailPage
    }
}

export default useNotifications
