import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

import NoticeRoutes from '../modules/notice/NoticeRoutes'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CommissionRoutes = lazy(() => import('../modules/commission/CommissionRoutes'))
  const BankSettingRoutes = lazy(() => import('../modules/bank-setting/BankSettingRoutes'))
  const TransactionPage = lazy(() => import('../modules/transactions/TransactionPage'))
  const NotificationPage = lazy(() => import('../pages/Notifications'))
  const PokerPartnerPage = lazy(() => import('../modules/poker-partner/PokerPartnerRoutes'))
  const TokenTransactionPage = lazy(() => import('../modules/token/Token.routes'))
  const FaqRoutes = lazy(() => import('../modules/faq/FaqRoutes'))
  const AdminRoutes = lazy(() => import('../modules/admins/AdminRoutes'))
  const RoleRoutes = lazy(() => import('../modules/roles/RoleRoutes'))
  const SettingsRoutes = lazy(() => import('../modules/settings/SettingsRoutes'))
  const KpiRoutes = lazy(() => import('../modules/kpi/KpiRoutes'))
  const SendEmailRoutes = lazy(() => import('../modules/email/EmailRoutes'))
  const CampaignRoutes = lazy(() => import('../modules/campaign/CampaignRoutes'))
  // const BankRoutes = lazy(() => import('../modules/banks/Routes'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/transactions/deposit' />} />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='commission/*'
          element={
            <SuspensedView>
              <CommissionRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='bank-setting/*'
          element={
            <SuspensedView>
              <BankSettingRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='notifications'
          element={
            <SuspensedView>
              <NotificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='game-account/*'
          element={
            <SuspensedView>
              <PokerPartnerPage />
            </SuspensedView>
          }
        />

        <Route
          path='token/*'
          element={
            <SuspensedView>
              <TokenTransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='faq/*'
          element={
            <SuspensedView>
              <FaqRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='notice/*'
          element={
            <SuspensedView>
              <NoticeRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='admins/*'
          element={
            <SuspensedView>
              <AdminRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='kpi/*'
          element={
            <SuspensedView>
              <KpiRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <RoleRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='email/*'
          element={
            <SuspensedView>
              <SendEmailRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/*'
          element={
            <SuspensedView>
              <CampaignRoutes />
            </SuspensedView>
          }
        />
        {/* <Route path='banks/*' element={<SuspensedView>
          <BankRoutes />
        </SuspensedView>} /> */}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
