import { ErrorMessage, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import ReactQuill, { ReactQuillProps } from "react-quill";
import 'react-quill/dist/quill.snow.css';

interface Props extends ReactQuillProps {
    name: string
}
const TextEditor = ({ name, ...props }: Props) => {

    const { setFieldValue } = useFormikContext()

    const handleChange = (value: string) => {
        setFieldValue(name, value)
    }

    return (
        <>
            <ReactQuill theme="snow" onChange={handleChange} {...props} />
            <FieldErrorMessage name={name} />
        </>
    )
}


interface ErrorMessageProps {
    name: string
}
const Message = () => {
    const { formatMessage } = useIntl()

    return (
        <span className='text-danger'>
            {
                formatMessage({
                    id: 'Required'
                })
            }
        </span>
    )
}
export const FieldErrorMessage = ({ name }: ErrorMessageProps) => <ErrorMessage name={name} component={Message} />

export default TextEditor

