/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

interface Props {
    value?: string| number,
    imageURL?: string
}

const ColumnCell: FC<Props> = ({ value, imageURL }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
							{value && value}
							{imageURL &&
									<img src={imageURL} width={60} height={60} alt="" className='image-contain'/>
							}
        </div>
    </div>
)

export default ColumnCell
