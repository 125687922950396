import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import NoticeForm from './NoticeForm'
import { storeNotice } from './requests'
import { NoticePayload, SubmitProps } from './schema'

const CreateNotice = () => {

    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const mutation = useMutation((payload: NoticePayload) => storeNotice(payload))
    const onSubmit: SubmitProps = (values, { setSubmitting }) => {
        setSubmitting(true)
        mutation.mutate(values, {
            onSuccess() {
                toast.success(formatMessage({ id: 'Faq created successfully.' }))
                navigate('/notice')
            },
            onError() {
                toast.error(formatMessage({
                    id: 'Something went wrong. Please try again'
                }))
            },
            onSettled() {
                setSubmitting(false)
            }
        })
    }
    return (
        <NoticeForm
            onSubmit={onSubmit}
            text={<FormattedMessage id='Create' />}
            title={<FormattedMessage id='Add notice' />}
        />
    )
}

export default CreateNotice
