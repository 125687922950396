import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { ListViewProvider } from '../apps/user-management/users-list/core/ListViewProvider'
import { KTCard, KTSVG, QUERIES } from '../../../_metronic/helpers'
import useCanAccess, { Permission } from '../../hooks/useCanAccess'

import { QueryRequestProvider } from './context/QueryRequestProvider'
import { QueryResponseProvider } from './context/QueryResponseProvider'

import NoticeTable from './tables/NoticeTable'
import { fetchNotices } from './requests'

const Notices: React.FC = () => {

    const navigate = useNavigate()
    const canAccess = useCanAccess()
    return (
        <>
            <QueryRequestProvider>
                <QueryResponseProvider queryKey={QUERIES.NOTICE_LIST} apiRequest={fetchNotices}>
                    <ListViewProvider>
                        <div className='d-flex justify-content-between mb-5'>
                            <div />
                            <h3 className='text-center mb-5'>
                                <FormattedMessage id="MENU.FAQ" />
                            </h3>
                            {canAccess(Permission.WRITE_FAQ) &&
                                <Button className='btn mr-3 fs-6 d-flex align-items-center' onClick={() => navigate('/notice/create')}>
                                    <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-1' />
                                    <span className='fs-4'>
                                        <FormattedMessage id="Create" />
                                    </span>
                                </Button>
                            }
                        </div>
                        <KTCard>
                            <NoticeTable />
                        </KTCard>
                    </ListViewProvider>
                </QueryResponseProvider>
            </QueryRequestProvider>
        </>
    )
}

export default Notices
