import { FC } from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bold text-danger mb-10' style={{fontSize:"140px"}}>404</h1>
      <h1 className='fw-bolder fs-2x text-gray-700 mb-10'>Whoops... this page is not available !</h1>
      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you are looking for doesn't exist.
      </div>
    </>
  )
}

export { Error404 }
