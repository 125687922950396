import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { useQueryResponseLoading, useQueryResponsePagination } from '../context/QueryResponseProvider'
import { useQueryRequest } from '../context/QueryRequestProvider'

const Pagination = () => {
  const intl = useIntl()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({ page, size: pagination.size || 10 })
  }

  return (
    <div className='d-flex align-items-center justify-content-end'>
      <div id='kt_table_users_paginate'>
        <ul className='pagination'>
          {pagination.links?.map((link) => (
            <li
              key={link.label}
              className={clsx('page-item', {
                active: Number(pagination.page) === link.page,
                disabled: isLoading,
                previous: link.label === '&laquo; Previous',
                next: link.label === 'Next &raquo;',
              })}
            >
              <span
                className='page-link cursor-pointer'
                onClick={() => updatePage(link.page)}
                dangerouslySetInnerHTML={{ __html: !isNaN(+link.label) ? link.label : intl.formatMessage({ id: link.label })}}
                style={{ cursor: 'pointer' }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Pagination 
