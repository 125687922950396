/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'

const ErrorsLayout = () => {
  return (
    <>
      <div className='d-flex align-items-center justify-content-center flex-root'>
        <img src={toAbsoluteUrl('/media/svg/illustrations/sigma/question.svg')} alt='not found' className='w-650px py-10' />

        <div className='d-flex flex-column w-800px text-center p-10 pb-lg-10'>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              <Link to='/' className='btn btn-lg btn-info fw-bolder pt-4'>
                Go to homepage
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://keenthemes.com' className='text-muted text-hover-primary px-2'>
            About
          </a>
          <a href='mailto:support@keenthemes.com' className='text-muted text-hover-primary px-2'>
            Contact
          </a>
          <a href='https://1.envato.market/EA4JP' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
    </>

  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export { ErrorsPage }
