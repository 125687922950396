import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import CreateNotice from './CreateNotice'
import EditNotice from './EditNotice'
import NoticeDetail from './NoticeDetail'
import Notices from './Notices'

const NoticeRoutes = () => (
    <Routes>
        <Route element={<Outlet />} />
        <Route path='create' element={<CreateNotice />} />
        <Route path=':notice/edit' element={<EditNotice />} />
        <Route path=':notice/detail' element={<NoticeDetail />} />
        <Route path='' element={<Notices />} />
        <Route index element={<Notices />} />
    </Routes>
)

export default NoticeRoutes
