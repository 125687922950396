/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    stringifyRequestQuery,
} from '../../../../_metronic/helpers'
import { NoticeQueryResponse, Notice } from '../_models'
import { useQueryRequest } from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Notice>(initialQueryResponse)
const QueryResponseProvider: FC<React.PropsWithChildren<{
    queryKey: string,
    apiRequest: (query: string) => Promise<NoticeQueryResponse>,
}>> = ({ children, queryKey, apiRequest }) => {
    const { state } = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery({
        ...state,
    }))

    const [, setSearchParams] = useSearchParams()
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setSearchParams(query)
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${queryKey}-${query}`,
        () => {
            return apiRequest(query)
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    )

    return (
        <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
            {children}
        </QueryResponseContext.Provider>
    )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const { response } = useQueryResponse()
    if (!response) {
        return []
    }

    return response?.data || []
}

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    }

    const { response } = useQueryResponse()
    if (!response || !response.payload || !response.payload.pagination) {
        return defaultPaginationState
    }

    return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
    const { isLoading } = useQueryResponse()
    return isLoading
}


const useRefetchRequest = () => {
    const { refetch } = useQueryResponse()
    return refetch
}
export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useRefetchRequest,
    useQueryResponseLoading,
}
